.LanguageCmbBox {
  border: none;
  background: none;

  margin-left: 15%;

  cursor: pointer;

  z-index: 5;
}

.LanguageCmbBox option {
  border: none;
  box-shadow: none;
  background-color: #002446;
}

/* Tablet Portrait */
@media (min-width: 600px) and (orientation: portrait) {
    .LanguageCmbBox {
        margin-left: 35%;
    }
}

/* Tablet Landscape */
@media (min-width: 900px) and (orientation: Landscape) {
    .LanguageCmbBox {
        margin-left: 60%;
    }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
    .LanguageCmbBox {
        margin-left: 22%;
    }
}

/* Desktop */
@media (min-width: 1760px) and (orientation: Landscape) {
    .LanguageCmbBox {
        margin-left: 40%;
    }
}

