.Button {
  padding: 0.3rem 1.5rem;

  color: #f8f8f8;
  /* background-color: #c34d56; */

  border: 2px #c34d56 solid;
  border-radius: 10px;
  /* box-shadow: 0 5px #803239;  */
  box-shadow: 0 0 4px 1px #d4545f;

  font-size: 1.2rem;
  /* font-weight: bold; */

  box-sizing: border-box;
}

.Button:hover {
  background-color: #a34149;
  /* box-shadow: none; */
}

/* Tablet Portrait */
@media (min-width: 600px) and (orientation: portrait) {
  .Button {
      font-size: 1.7em;
  }
}