.Main {
  height: 90vh;
  position: relative;

  padding-top: 5rem;

  background-color: #002446;

  background-image: url("../../assets/images/Wave.svg"),
    url("../../assets/images/Stars2.svg"),
    linear-gradient(to bottom, #002446 0%, #002446 45%, #000e20 100%);
  background-size: contain, contain;
  background-repeat: no-repeat, no-repeat;
  background-position-y: 101%, 35%;

  z-index: 1;
}

.Main .moon {
  position: absolute;
  top: 15%;
  left: 0;

  transform: translate(-45%, 0%);

  width: 13rem;
  height: 13rem;

  background-color: #f0f0f0;
  border-radius: 50%;

  z-index: 1;

  animation: glow 1s infinite alternate ease-in-out;
}

@keyframes glow {
    from {
      box-shadow: 0 0 10px 5px #ffffffc4;
    }
    
    to {
      box-shadow: 0 0 10px 10px #ffffffc4;
    }
}


.Main .moon .crater1, .crater2, .crater3 {
    position: absolute;

    width: 1.5rem;
    height: 1.5rem;

    border-radius: 50%;
    
    background-color: #d6d6d683;

    z-index: 2;
}

.Main .moon .crater1 {
    top: 15%;
    left: 50%;
}

.Main .moon .crater2 {
    top: 45%;
    left: 70%;
}

.Main .moon .crater3 {
    top: 70%;
    left: 55%;
}

.Main .stars,
.stars2 {
  position: absolute;
  top: 15%;
  right: 0;

  width: 10rem;
  height: auto;

  z-index: 1;
}

.Main .stars2 {
  top: 35%;
  right: 15%;
}

.Main .githubLogo {
  position: absolute;
  bottom: 20%;
  right: 15%;

  width: 2.5rem;
  height: auto;
}

.Main .githubLogo:hover {
  box-shadow: 0 8px 8px -6px #f0f0f0;
}

.Main .githubLogo img {
  width: 100%;
  height: 100%;
}

/* Tablet Portrait */
@media (min-width: 600px) and (orientation: portrait) {
  .Main {
    background-image: url("../../assets/images/Wave2.svg"),
      url("../../assets/images/Stars2.svg"),
      linear-gradient(to bottom, #002446 0%, #002446 45%, #000e20 100%);
  }

  .Main .moon {
    width: 27rem;
    height: 27rem;
  }

  .Main .moon .crater1, .crater2, .crater3 {
    width: 2.5rem;
    height: 2.5rem;
  }

  .Main .moon .crater1 {
    left: 65%;
  }

  .Main .moon .crater2 {
    left: 80%;
  }

  .Main .moon .crater3 {
    left: 65%;
  }

  .Main .stars,
  .stars2 {
    width: 15rem;
  }

  .Main .stars2 {
    right: 20%;
  }

  .Main .githubLogo {
    bottom: 28%;

    width: 2.5rem;
  }
}

/* Tablet Landscape */
@media (min-width: 900px) and (orientation: Landscape) {
  .Main {
    background-image: url("../../assets/images/Wave2.svg"),
      url("../../assets/images/Stars-Test3.svg"),
      linear-gradient(to bottom, #002446 0%, #002446 45%, #000e20 100%);
    background-size: contain, contain;
    background-repeat: no-repeat, no-repeat;
  }

  .Main .moon {
    width: 25rem;
    height: 25rem;
  }

  .Main .moon .crater1, .crater2, .crater3 {
    width: 2rem;
    height: 2rem;
  }

  .Main .moon .crater1 {
    left: 65%;
  }

  .Main .moon .crater2 {
    left: 80%;
  }

  .Main .moon .crater3 {
    left: 65%;
  }

  .Main .stars,
  .stars2 {
    width: 12rem;
  }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
  .Main {
    background-image: url("../../assets/images/Wave2.svg"),
      url("../../assets/images/Stars-Test3.svg"),
      linear-gradient(to bottom, #002446 0%, #000e20 100%);
    background-position-x: 0%, 25%;
    background-position-y: 125%, 20%;
  }

  .Main .moon {
    top: 15%;
    transform: translate(-30%, 0%);
    width: 30rem;
    height: 30rem;
  }

  .Main .moon .crater1, .crater2, .crater3 {
    width: 2rem;
    height: 2rem;
  }

  .Main .moon .crater1 {
    left: 65%;
  }

  .Main .moon .crater2 {
    left: 80%;
  }

  .Main .moon .crater3 {
    left: 65%;
  }
}
