.Hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 30px;
  height: 20px;

  z-index: 5;
}

.Hamburger .line {
  width: 100%;
  height: 3px;

  background-color: #f0f0f0;
  border-radius: 5px;
}

.Hamburger > .line {
  transition: all 0.3s ease-in-out;
}

.Hamburger.active .line:nth-child(1) {
  transform-origin: center center;
  transform: rotate(-45deg) translate(-6px, 7px);
}

.Hamburger.active .line:nth-child(2) {
  transform: translateX(10px);
  opacity: 0;
}

.Hamburger.active .line:nth-child(3) {
  transform-origin: center center;
  transform: rotate(45deg) translate(-5px, -5px);
}

/* Tablet Portrait */
@media (min-width: 600px) {
  .Hamburger {
    width: 40px;
    height: 25px;
  }

  .Hamburger .line {
    height: 4px;
  }

  .Hamburger.active .line:nth-child(3) {
    transform: rotate(45deg) translate(-7px, -7px);
  }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
  .Hamburger {
    display: none;
  }
}