.Section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  padding-bottom: 1.5rem;
}

.Section:last-child {
  width: 100%;

  margin-top: 2rem;

  background-color: #002446;
}

.Section .general {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Section .title {
  font-size: 2.2rem;
  font-weight: 900;
}

.Section .img {
  height: auto;
}

.Section .ProjectsImage {
  width: 65%;
}

.Section .SkillsImage {
    width: 80%;
}

.Section .AboutMeImage {
  width: 80%;
}

/* Tablet Portrait */
@media (min-width: 600px) and (orientation: portrait) {
  .Section .title {
    font-size: 3em;
  }
}

/* Tablet Landscape */
@media (min-width: 900px) and (orientation: Landscape) {
  .Section .ProjectsImage {
    width: 50%;
  }

  .Section .SkillsImage {
    width: 61%;
  }

  .Section .AboutMeImage {
    width: 60%;
  }

  .Section .title {
    font-size: 3em;
  }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
  .Section {
    width: 100%;

    flex-direction: row;
    justify-content: space-evenly;

    position: relative;

    padding-top: 8rem;
    padding-bottom: 6rem;

    box-sizing: border-box;
  }

  .Section .general {
    display: flex;
    width: 45%;
    /* align-items: flex-start; */
  }

  .Section .title {
    position: absolute;
    top: 7%;
    left: 10%;
    margin: 0;
  }

  .Section .ProjectsImage {
    width: 65%;
    bottom: 0;

    padding-top: 10%;
  }

  .Section .SkillsImage {
    width: 70%;
  }

  .Section .AboutMeImage {
    width: 70%;

    padding-top: 10%;
    padding-right: 15%;
  }


  .invertedSection {
    flex-direction: row-reverse;
  }
  
  .invertedSection .general {
    align-items: flex-end;
  }
  
}