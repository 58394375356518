.projects p {
  text-align: center;
  padding: 0.5rem 10%;
}

.searchButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchParametersTitle {
  text-align: center;

  font-size: 1.3rem;
}

/* Tablet Portrait */
@media (min-width: 600px) and (orientation: portrait) {
  .searchParametersTitle {
    font-size: 2rem;
  }
}

/* Tablet Landscape */
@media (min-width: 900px) and (orientation: Landscape) {
  .searchParametersTitle {
    font-size: 2rem;
  }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
  .projects {
    display: flex;
    flex-wrap: wrap;
  }

  .searchParametersTitle {
    font-size: 2rem;
  }
}
