.Footer {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: auto;

    padding: 0.2rem;
    margin: 0;

    background-color: #003143da;

    box-sizing: border-box;
}

.Footer p {
    text-align: center;
}