body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p, img, label, input, textarea, select, select::after {
  color: #f0f0f0;
}

a {
  text-decoration: none;
}

a:hover,
a:active,
a:focus,
input:hover,
input:active,
input:focus,
button:hover,
button:active,
button:focus,
textarea:hover,
textarea:active,
textarea:focus,
select,
option {
  outline: none;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}