.Link {
  color: #a8dadc;
  border: 2px #a8dadc solid;

  padding: 0.5rem 1rem;
  margin-top: 1rem;

  box-shadow: 0 0px 12px 3px #a8dadc;

  box-sizing: border-box;
}

.Link:hover {
  background-color: #a8dadc;
  color: #000000;
}

/* Tablet Portrait */
@media (min-width: 600px) and (orientation: portrait) {
  .Link {
    margin-top: 3rem;

    font-size: 1.3em;
  }
}

/* Tablet Landscape */
@media (min-width: 900px) and (orientation: Landscape) {
  .Link {
    margin-top: 2.2rem;
  }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
  .Link {
    margin-top: 0rem;
  }
}
