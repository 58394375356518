.NavigationItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */

  visibility: hidden;
  position: fixed;

  width: 100vw;
  height: 100vh;

  transform: translateX(100vw);

  background-color: #002446;

  top: 0;
  left: 0;
  right: 0;
/* 
  -webkit-transition: height, 4s;
  -moz-transition: height, 4s;
  -o-transition: height, 4s; */
  transition: all 200ms linear;

  z-index: 4;
}

.NavigationItem ul {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  margin: 2rem 0 0 0;
  list-style: none;
  padding: 0;

  height: 50%;
}

.visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  visibility: initial;

  transform: translateX(0vw);

  /* width: 100vw; */
  /* height: 100vh; */
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
  .NavigationItem {
    flex-direction: row;

    position: relative;
    display: flex;
    justify-content: flex-end;

    visibility: initial;

    transform: none;

    width: 60%;
    height: auto;

    background-color: transparent;

    z-index: 5;
  }
  
  .NavigationItem ul {
    flex-direction: row;
  
    margin: 0;
    margin-right: 10%;
  }
  .visible {
    height: auto;
    flex-direction: initial;
  }
}
