.Contact {
  padding: 5rem 0 3rem 0;

  min-height: 95vh;

  box-sizing: border-box;
}

.Contact form {
    display: flex;
    flex-direction: column;

    margin-top: 2rem;
}

.Contact form input,
.Contact form textarea {
    background-color: transparent;
    border: 2px #e7e7e7 solid;
    box-shadow: 0 0 2px 0.8px #f0f0f0;

    margin: 0.5rem 0;
    padding: 0.5rem;
}

.Contact form input {
    height: 0.8rem;
}

.Contact form textarea {
    height: 5rem;
}

.Contact form input::placeholder,
.Contact form textarea::placeholder {
    color: #ffffffc9;
}

.Contact form input[type=submit] {
    border-color: #C34D56;
    box-shadow: 0 0 4px 1px #d4545f;
    border-radius: 5px;

    width: 8rem;
    height: 2rem;

    margin: 0.5rem auto 0 auto;
    padding: 0;
}

.Contact form input[type=submit]:active,
.Contact form input[type=submit]:hover {
    background-color: #C34D56;
    cursor: pointer;
}

/* Tablet Portrait */
@media (min-width: 600px) and (orientation: portrait) {
    .Contact form input, .Contact form textarea {
        width: 25rem;
    }
}

/* Tablet Landscape */
@media (min-width: 900px) and (orientation: Landscape) {
    .Contact form input, .Contact form textarea {
        width: 25rem;
    }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
    .Contact form input, .Contact form textarea {
        width: 22rem;
    }

    .Contact form textarea {
        height: 10rem;
    }
}