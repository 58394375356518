.link {
  color: #f0f0f0;
}

.link:active,
.link.active {
  color: #a8dadc;
  /* border-bottom: 2px #A8DADC solid; */
  box-shadow: 0 8px 8px -6px #a8dadc;
}

.link:hover {
  color: #a8dadc;
}

@media (min-width: 600px) {
  .link {
    font-size: 1.4em;
  }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
  .link {
    margin-right: 3rem;
  }
}
