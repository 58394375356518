.Header {
  display: flex;
  position: fixed;

  justify-content: space-between;
  align-items: center;

  /* left: 50%; */
  top: 0;

  /* transform: translate(-50%, 0); */

  width: 100vw;

  /* background-color: #003143b2; */
  padding: 1rem 1.5rem;

  z-index: 5;

  transition: background-color 150ms linear;

  box-sizing: border-box;
}

.Header .logo {
  width: 10rem;
  height: auto;
  z-index: 5;
}

.Header a {
  z-index: 5;
}

.navBarColor {
  background-color: #003143da;
}

/* Tablet Portrait */
@media (min-width: 600px) and (orientation: portrait) {
  .Header .logo {
    width: 13em;
  }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
  .Header {
    padding: 1rem 5rem;
  }
}
