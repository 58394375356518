.Skills {
  text-align: center;

  margin-top: 2rem;
}

.Skills .logo {
  width: 3rem;
  height: auto;

  margin: 0 0.3rem;
}

.Skills p {
  font-weight: 700;
}

/* Tablet Portrait */
@media (min-width: 600px) and (orientation: portrait) {
  .Skills p {
    font-size: 1.6em;
  }

  .Skills .logo {
    width: 3.7em;
  }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
  .Skills p {
    font-size: 1.3em;
    text-align: left;
  }
}
