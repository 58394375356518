.AboutMe {
    padding: 5rem 0 3rem 0;

    min-height: 95vh;

    box-sizing: border-box;
}

.AboutMe p {
    padding: 0 2rem;
}