.Project {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 5.5rem 1rem 2.5rem 1rem;

  min-height: 95vh;

  box-sizing: border-box;
}

.Project .video {
  margin: 2rem 0;
  width: 100%;
  height: 13rem;
}

.Project .descriptionContainer p {
  margin: 1.5rem 0;
}

.Project .externalLinkContainer {
  margin: 2rem;
}

.Project .externalLinkContainer a {
  margin: 0 1rem;
}

/* Tablet Portrait */
@media (min-width: 600px) and (orientation: portrait) {
  .Project {
    justify-content: space-evenly;

    height: 100vh;
  }

  .Project h2 {
    font-size: 2.9em;
  } 

  .Project .video {
    width: 90%;
    height: 40%;
  }

  .Project p {
    font-size: 1.2em;
  }
}

/* Tablet Landscape */
@media (min-width: 900px) and (orientation: Landscape) {

  .Project h2 {
    font-size: 2.5em;
  }

  .Project .video {
      width: 80%;
      height: 25rem;
  }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
  .Project .video {
      width: 75%;
      height: 40rem;
  }

  .Project p {
    font-size: 1.2em;
  }

  .Project .externalLinkContainer a {
    padding: 0.5rem 2rem;
  }
}
