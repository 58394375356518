.SearchButton {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;

  margin-top: 1rem;

  width: 12rem;
  height: 2rem;

  background-color: #c34d56;
}

.SearchButton:hover,
.SearchButton:active {
  background-color: #9e3941;
  cursor: pointer;
}

/* Tablet Portrait */
@media (min-width: 600px) and (orientation: portrait) {
  .SearchButton {
    width: 18rem;
    height: 3rem;

    font-size: 1.2em;
  }
}

/* Tablet Landscape */
@media (min-width: 900px) and (orientation: Landscape) {
  .SearchButton {
    width: 20rem;
    height: 3rem;

    font-size: 1.2em;
  }
}

/* Laptop */
@media (min-width: 1200px) and (orientation: Landscape) {
  .SearchButton {
    width: 18rem;
    height: 3rem;

    font-size: 1.3em;
  }
}
